
//load libs
@import "../libs/normalize.less";
@import "../libs/bootstrap.less";
@import "../libs/font-awesome.less";
@import "../libs/sweetalert.less";
@import "../libs/slick.less";
@import "../libs/lightbox.less";
@import "../libs/slick-theme.less";
@import "../../fonts/fonts.less";


//Custom
@import "../core/variables.less";

*,
*:hover,
*:focus,
*:visited,
*:active {
	outline: 0 !important;
}

body {
	font-size: 16px;
	font-family: 'Source Sans Pro';
}

.container-fluid {
	max-width: 1900px;
	margin: 0 auto;
}

.reset-list {
	margin: 0;
	padding: 0;
}

.inline-block {
	display: inline-block;
	*display: inline;
	zoom: 1;
}

.transition(@args:200ms) {
  -webkit-transition: @args;
  -moz-transition: @args;
  -o-transition: @args;
  -ms-transition: @args;
  transition: @args;
}

.text-center {
	text-align: center;
}

img {
	max-width: 100%;
	height: auto;
}

.vertical-align {
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.pagination {
	.reset-list;
	margin: 1rem 0;
	text-align: center;

	li {
		.inline-block;
		list-style: none;
		margin: 0 .2rem;

		span,
		a {
			display: block;			
			padding: .5rem 1rem;
			border-radius: 3px;
		}

		span {
			background: @primary-color;
			color: @terceary-color;
		}

		a {
			border: 1px solid @primary-color;
			color: @primary-color;

			&:hover {
				background: @primary-color;
				color: @terceary-color;
			}
		}

		&.disabled span {
			background: fade(@primary-color, 65)
		}
	}
}

#recaptcha2,
#recaptcha1 {
	overflow: hidden;
}

.alert {
	text-align: center;
    padding: 1rem;
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: 'HelveticaNeue LT 45';

    &.alert-error {    	
	    background: #ef7d7d;
	    color: #fff;
    }

    &.alert-warning {    	
	    background: #ecb258;
	    color: #fff;
    }

    &.alert-success {    	
	    background: #9ecc83;
	    color: #fff;
    }
}

//Flex Upload
.FlexUpload-drop {
	position: relative;
	margin: 1rem 0;
	border: 1.5px dashed #c7c7c7;
    background-color: #eee;					    
	font-size: 1.25rem;
	text-align: center;					   
}

.FlexUpload-drop.is-dragover {
	outline-offset: -20px;
	outline-color: #c8dadf;
	background-color: #fff;
}

.FlexUpload-drop input[type='file'] {
	display: block;
	width: 100%;
	height: 100%;						
	padding: 1rem 2rem;
	opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.FlexUpload-drop .box-dragndrop {
	color: #333;
}

.FlexUpload-drop input[type='file'] + label {
	display: block;
	margin-bottom: 2rem;
}

.FlexUpload-drop .box-uploading,
.FlexUpload-drop .box-error {
  display: none;
  margin-bottom: 2rem;
}

.FlexUpload-drop .box-icon {
    width: 100%;
    height: 40px;
    fill: #383333;
    display: block;
    margin: 2rem 0;
}

.FlexUpload-Panel {
	margin: 2rem 0;
	padding: 1rem 2rem;
	max-height: 400px;
	overflow-y: auto;
	background: #fff;
	border: 1.5px solid #eee;
}

.FlexUpload-Panel ul {
	margin: 0;
	padding: 0;
}

.FlexUpload-Panel .options {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

.FlexUpload-Panel article:hover .options {
	display: block;
}

.FlexUpload-Panel .options li {
	display: inline-block;
	*display: inline;
	zoom: 1;
	margin: .5rem;
}

.FlexUpload-Panel .options li a {
	display: block;
	padding: .5rem 1rem;
	background: #3f49a5;
	color: #fff;
	font-size: 1.4rem;
	border-radius: 5px;
	cursor: pointer;
}

.FlexUpload-Panel .options li a.btn-delete {
	background: #d81e1e;
}

.FlexUpload-Panel img {
	max-width: 100% !important;
	max-height: 320px;
}

.FlexUpload-Panel .text-center {
	text-align: center;
}

.FlexUpload-Panel .vertical-align {
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.FlexUpload-Panel::-webkit-scrollbar {
    width: .5em;
    background: #fff;
}
 
.FlexUpload-Panel::-webkit-scrollbar-track {
	background: rgba(240, 240, 240, 0.5);
}
 
.FlexUpload-Panel::-webkit-scrollbar-thumb {
  background-color: #383333;
}

.FlexUpload-Panel article {
	position: relative;
	margin: 1.5rem 0; 
}

.badge {
	display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}